.chevronGroup {
}

.chevronGroup .chevronCircle {
  transition: background-color ease 350ms;
}

.chevronGroup:hover .chevronCircle {
  background-color: black;
}
