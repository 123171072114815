.horizontalBannerScroller {
  min-height: 220px;
}

@media (max-width: 380px) .horizontalBannerScroller  {
  min-height: 460px;
}

@media (min-width: 768px) .horizontalBannerScroller  {
  min-height: 380px;
  overflow: hidden;
}

@media (min-width: 1280px) {
  .horizontalBannerScroller :global(.slick-slider) :global(.slick-dots) {
    bottom: 12px !important;
    right: 12px !important;
    text-align: right;
  }
}

.horizontalBannerScroller :global(.title-image) {
  opacity: 0;
  transform: translateX(36px);
  transition: transform 500ms ease-out 150ms;
}

.horizontalBannerScroller :global(.slick-active) :global(.title-image) {
  transform: translateX(0);
  transition: transform 500ms ease-out 150ms, opacity 700ms ease-out 150ms;
  opacity: 100;
}

.horizontalBannerScroller :global(.description-text) {
  opacity: 0;
}

.horizontalBannerScroller :global(.slick-active) :global(.description-text) {
  opacity: 1;
  transition: opacity 700ms ease 950ms;
}

.horizontalBannerScroller :global(.slick-slide) :global(.banner-content-container) {
  border-color: transparent !important;
}

.horizontalBannerScroller :global(.slick-slide.slick-active) :global(.banner-content-container:hover) {
  border-color: white !important;
}
